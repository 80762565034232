.btn-politicas {
    background-color: #002a5d;
    color: #fff;
    padding: 0.5rem;
    border-radius: 33.5px;
    width: 10rem;
    border: none;
}
.btn-politicas:hover {
    background-color: #24538B;
}
.accordion-button:hover,
.accordion-button:focus,
.accordion-button:active {
    background: none;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
.btn-politicas:hover {
    background-color: #24538B;
}
.accordion-button {
    padding: 0 !important;
    border: none !important;
    outline: none !important;
}

.accordion-button:not(.collapsed){
    background: none !important;
    box-shadow: none !important;
}
.modal-permissao {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(82, 82, 82, 0.59);
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.hidden {
    display: none;
}
.label-switch {
    color: #06347A;
    margin-left: .5rem;
    margin-bottom: 0;
}
.container-permissoes {
    border: 1px solid #D3D3D3;
    padding: 3rem;
    margin-top: 2rem;
    border-radius: 1rem;
}
.linha-permissao {
    margin: 2rem 0;
}
.icone-permissao {
    width: 1rem;
}
