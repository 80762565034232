.modal-tipo-solicitacao {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(82, 82, 82, 0.59);
    z-index: 10000000000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.close-modal-tipo-solicitacao {
    border: none;
    position: relative;
    font-size: 1.5rem;
    background-color: white;
    font-weight: bold;
    color: #FF8686;
}

.modal-tipo-solicitacao-header {
    display: flex;
    flex-direction: row;
    padding: 2rem 0;
}

.hidden {
    display: none;
}
