.toast-area{
    z-index: 10;
    position: fixed;
    right: 0.5rem;
    top: 5rem;
}

.color-success{
    color: #63E7A7;
}

.background-success{
    background-color: #63E7A7;
}


.color-error{
    color: #FF8686;
}

.background-error{
    background-color: #FF8686;
}

.title-and-label{
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.close-toast{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 8px 8px 0;
    border: none;
    background: none;
    color: #6C7480;
}

.close-toast:hover{
    background-color: rgb(243, 243, 243);
}

#close-toast-btn{
    font-size: 1rem;
}

#close-toast-btn:hover{
    cursor: pointer
}
