.table>:not(:last-child)>:last-child>* {
    border-bottom: #DFDFDF80 1px solid !important;
}

.table td {
    vertical-align: middle !important;
    padding: 0.8rem 0.4rem !important;
}

.no-scroll {
    overflow: hidden;
}

.hidden {
    display: none;
}
