#btnGroupDrop{
    min-width: auto !important;
    width: auto !important;
    color: #FF6262;
}

.btn-check:focus+.btn, .btn:focus{
    box-shadow: none !important;
}

.dropdown-toggle::after{
    content: '' !important;
    border: none !important;
}

.drop-menu-delete{
    z-index: 1000 !important;
}
.passagem-aereo {
    background-color: #ECECEC;
    display: flex;
    border-bottom: 2px dashed #6C7480;
    border-radius: .4rem;
    flex-direction: column;
    padding-bottom: 2rem;
}

.trecho-aereo {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.trecho-aereo > .label {
    background-color: #10478A;
    color: #FFF;
    width: 4rem;
    border-radius: 1rem;
    text-align: center;
    display: block;
    margin: 0;
}

.container-trecho {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
}
.codigo-label{
    color: #6C7480;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
}

.title-passagem {
    color: #0B4BB1;
    font-weight: bold;
    font-size: medium;
    text-transform: uppercase;
}

.valor-adiantamento{
    margin: 0 !important;
}

.input-area-adiantamento{
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-personalizado{
    outline: 1px solid #1955b44f !important;
    font-size: 12px;
    height: 2rem;
    width: 80%;
    padding: 0 0.5rem !important;
    margin: 1rem 0;
    border-radius: 6px;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.10);
    text-align: center;
}

.input-personalizado:focus-visible{
    outline: 2px solid #0b4bb188 !important;
}

.inline{
    display: flex;
    align-items: center;
    width: 100%;
}
